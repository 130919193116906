import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Seo = ({ title, description, isHomePage }) => {
  const schemaOrg = {
    "@context": "http://www.schema.org",
    "@type": "Organization",
    name: "LT3 Advanced Technology Group",
    url: "https://lt3atg.com/",
    logo: "https://lt3atg.com/img/lt3_logo_flat.png",
    image: "https://lt3atg.com/img/lt3_logo_flat.png",
    description:
      "We build innovative software and technology products that push beyond the limits of technological uncertainty to disrupt industries and delight customers.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "1039 17 Ave SW #805",
      addressLocality: "Calgary",
      addressRegion: "Alberta",
      postalCode: "T2T 0B1",
      addressCountry: "Canada",
    },
    location: {
      "@type": "Place",
      geo: {
        "@type": "GeoCoordinates",
        latitude: "51.037794799919105",
        longitude: "-114.08665857282907",
      },
      hasMap:
        "https://www.google.com/maps?q=51.037794799919105,-114.08665857282907",
    },
  };

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;
  const titleSuffix = site.siteMetadata?.title;

  // useEffect(() => {
  //   // Load google tag manager script
  //   window.dataLayer = window.dataLayer || [];
  //   function gtag() {
  //     window.dataLayer.push(arguments);
  //   }
  //   gtag("js", new Date());
  //   gtag("config", "G-88XLL2017N");
  // }, []);

  return (
    <Helmet
      titleTemplate={titleSuffix && !isHomePage ? `%s | ${titleSuffix}` : null}
    >
      <html lang="en" />
      <meta charset="UTF-8" />
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <meta
        property="og:title"
        content={
          titleSuffix && !isHomePage ? `${title} | ${titleSuffix}` : title
        }
      />
      <meta property="og:url" content="https://lt3atg.com/" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://lt3atg.com/lt3atg.png" />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:site_name" content="LT3 Advanced Technology Group" />

      {/* Favicon Stuff */}
      <meta name="msapplication-TileColor" content="#FF8C00" />
      <meta name="theme-color" content="#FF8C00" />
      {/* <meta
        name="google-site-verification"
        content="GoogleSiteVerificationKey"
      /> */}
      <script>{`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-PN82XKF');
      `}</script>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      {/* <link rel="manifest" href="/site.webmanifest" /> */}
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#FF8C00" />
      <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
      {/* <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-88XLL2017N"
        ></script> */}
    </Helmet>
  );
};

Seo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Seo;
